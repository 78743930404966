import React from "react";
import styles from "./Home.module.scss"
import morePic from 'url:../../../assets/images/more.jpg'
import vizuallLogo from 'url:../../../assets/images/vizuall-logo.png'
import sparekassenLogo from 'url:../../../assets/images/sparekassen-logo.png'
import bryghusLogo from 'url:../../../assets/images/bryghus-logo.png'
import {Header} from "../../components/Header/Header";
import {Social} from "../../components/Social/Social";
import {animated, useSpring} from '@react-spring/web'

export const Home = (): React.ReactElement => {
    const props = useSpring({
        to: {opacity: 1},
        from: {opacity: 0},
        delay: 350,
        config: { duration: 500 },
    })

    return (
        <animated.div style={props}>
            <main>
                <Header/>

                <section className={`${styles.nextEvent} horizontal`}>
                    <div>
                        <div className={styles.nextEventInfo}>
                            <h3 className={"left"}>Sæt <span style={{fontSize: "48px"}}
                                                             className="handwritten">X</span> i
                                kalenderen</h3>

                            <p>Endelig er der nyt fra tilflytterbaren - og det er godt nyt! 😃</p>
                            <p>Kalder alle tilflyttere, tilbageflyttere eller thyboer med lyst til at møde nye
                                mennesker! Vi
                                skal mødes, vi skal feste og vi skal på vandet! (Hvis man vil) 🤸💃🥳
                                Det er nemlig med stor glæde, at vi nu kan afsløre, at næste, længe ventede,
                                tilflytterbar
                                bliver fredag den 13. august fra kl. 15 🎉🌟
                                Baren afholdes denne gang som et udendørs arrangement ved Synopal Havn - og vi har
                                bestilt
                                godt vejr ☀️ Der vil i den forbindelse være mulighed for at prøve den nye cable
                                park-bane
                                dog mod egenbetaling. Derudover arbejder vi på at man kan få lov at prøve kræfter med
                                SUP
                                🏄🤙</p>

                            <p className={styles.handwritten}>Vi glæder os til at se dig!<br/>- Lars, Christian, Jimmi,
                                Lisbeth & Kira</p>
                        </div>
                    </div>
                    <div className={styles.nextEventBackground}/>
                </section>

                <section className={`${styles.sponsors} vertical`}>
                    <h3 style={{marginBottom: "12px"}}>Tak til vores sponsorer</h3>
                    <p>For at bidrage til en fed tilflytterbar og for at bakke op om fællesskabet for de nye i Thy!</p>

                    <div className={styles.sponsorImages}>
                        <img className={"sponsor-image"} src={vizuallLogo} width={200}/>
                        <img className={"sponsor-image"} src={sparekassenLogo} width={200}/>
                        <img className={"sponsor-image"} width={200} src={bryghusLogo}/>
                    </div>
                </section>

                <section className={`${styles.more} horizontal`}>
                    <div>
                        <h3>Mere fra Tilflytterbar</h3>
                        <img className={styles.moreBackground} src={morePic} alt={"Stemningsbillede"}/>
                    </div>
                    <div>
                        <p className="handwritten" style={{fontSize: "24px"}}>Følg med på Facebook og Instagram</p>

                        <Social media={"facebook"} text={"/tilflytterbarithy"}
                                link={"https://www.facebook.com/tilflytterbarithy"}/>
                        <Social media={"instagram"} text={"/tilflytterbarithy"}
                                link={"https://www.instagram.com/tilflytterbarithy/"}/>
                    </div>
                </section>

                <section className={styles.mission} style={{display: "none"}}>
                    <div>
                        <h3>Mission</h3>
                        <p>LoremLorem Ipsum is simply dummy text of the printing and typesetting industry. Ipsum is
                            simply
                            dummy text of the printing and typesetting industry.</p>
                    </div>
                    <div>
                        <h3>Vision</h3>
                        <p>LoremLorem Ipsum is simply dummy text of the printing and typesetting industry. Ipsum is
                            simply
                            dummy text of the printing and typesetting industry.</p>
                    </div>
                    <div>
                        <h3>Værdier</h3>
                        <p>LoremLorem Ipsum is simply dummy text of the printing and typesetting industry. Ipsum is
                            simply
                            dummy text of the printing and typesetting industry.</p>
                    </div>
                </section>
            </main>

            <footer>
                <p>Tilflytterbar i Thy, 2021</p>
            </footer>
        </animated.div>
    );
}