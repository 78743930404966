import React from "react";
import styles from "./Header.module.scss";

export const Header = () => {
    return (
        <section className={styles.home}>
            <h1>Tilflytterbar</h1>
            <h2>For tilflyttere, tilbageflyttere og alle derimellem, <br/>som vil lære nye mennesker at kende i Thy
            </h2>
        </section>
    );
}