import instagramLogo from "url:../../../assets/images/instagram-logo.svg";
import facebookLogo from 'url:../../../assets/images/facebook-logo.svg'
import React from "react";
import {Types} from "./types";
import styles from "./Social.module.scss"

export const Social = (types: Types) => {
    let logo = instagramLogo;
    if (types.media === "facebook") {
        logo = facebookLogo;
    }
    return (
        <div className={styles.social}>
            <a href={types.link}>
                <img className={styles.socialIcon} width={85} height={85} src={logo}/>
            </a>
            <a href={types.link}>{types.text}</a>
        </div>
    );
}